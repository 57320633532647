import { useState, useEffect, KeyboardEvent } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import PlaceholderImage from '../../../../components/PlaceholderImage/PlaceholderImage';
import { ReactComponent as restaurantHeaderPlaceholderSVG } from '../../../../assets/images/placeholder-images/restaurant-header-placeholder.svg';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import Selection from '../../../../components/Selection/Selection';
import StoreInfoDialog from '../../../../components/RestaurantInfoDialog';
import { DaysOfWeek, StoreInterface } from '../../../../types/stores';
import { useTimeDisplay } from '../../../../hooks/useTimeDisplay';
import { format } from 'date-fns';
import { isStoreOpen } from '../../../../utils/Store/isStoreOpen';
import { useSiteConfig } from '../../../../hooks/useSiteConfig';
import StatusLabel from '../../../../components/StatusLabel/StatusLabel';
import Banner from '../../../../components/Banner/Banner';
import formatDisplayTime from '../../../../utils/Menu/formatDisplayTime';
import { zonedTimeToUtc } from 'date-fns-tz';
import { isStoreAboutToClose } from '../../../../utils/Store/isStoreAboutToClose';
import { useMenu } from '../../../../hooks/useMenu';

interface MenuHeaderProps {
  store: StoreInterface;
}

const MenuHeaderContainer = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== 'headerImageUrl'
})<{ headerImageUrl: string; alt: string }>(({ theme, headerImageUrl }) => ({
  height: 210,
  backgroundImage: `linear-gradient(360deg, #000000, rgba(0, 0, 0, 0)), url(${headerImageUrl})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  padding: theme.spacing(4),
  color: theme.colors.base.white
}));

const BannerContainer = styled(Box)(() => ({
  marginBottom: 0
}));

const BannerHeaderContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(13)
}));

const RestaurantPlaceholder = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'screenWidth'
})<{ screenWidth: number }>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 20
}));

const RestaurantSubtitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(1.5)
}));

const RestaurantSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.colors.base.white,
  letterSpacing: 0.25,
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2
}));

const HeaderInfoContainer = styled('div')(({}) => ({
  display: 'flex',
  alignItems: 'center'
}));

const RestaurantInfoText = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  fontWeight: 400,
  paddingLeft: theme.spacing(1),
  color: theme.colors.base.white
}));

const BottomLineStyled = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

const StyledClosed = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));

const MenuHeader = ({ store }: MenuHeaderProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [bannerVisible, setBannerVisible] = useState(false);
  const [closingTimeToday, setClosingTimeToday] = useState('');
  const { partnerConfig: siteConfig } = useSiteConfig();
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const { pickupTimeDisplay, handleTimeSelection, displayTimes } =
    useTimeDisplay(store?.id || '');
  const { data } = useMenu(store ? store.id : '');

  const logEvent = useAnalytics();

  useEffect(() => {
    if (!store.shortId) {
      return;
    }
    const today = format(new Date(), 'EEEE');
    const status = isStoreOpen(
      store.days[today as DaysOfWeek],
      siteConfig.timeZone
    );
    const analyticPayload = {
      status: status ? 'open' : 'closed',
      orderingAvailable: store.isMobileActive
    };
    setIsOpen(status);
    logEvent('view_restaurant', analyticPayload);
  }, [store.shortId, store.days, siteConfig.timeZone]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleKeyOpen = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== 'Enter') {
      return;
    }

    event.stopPropagation();
    event.preventDefault();
    handleClickOpen();
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const getBannerStatus = (rightNow: Date) => {
    if (data?.menuHours) {
      // Returns store end time in 24-hour format
      const dayOfWeek = format(rightNow, 'EEEE');
      const todaysStoreHours = data.menuHours.filter((menuDay) => menuDay[dayOfWeek])
      const storeClosingTime = todaysStoreHours[0][dayOfWeek].endTime;
      setClosingTimeToday(storeClosingTime);

      // Returns user local time in store's timezone in 24-hour format
      const usersTimeObject = zonedTimeToUtc(rightNow, siteConfig.timeZone);
      const usersFormattedTime = format(usersTimeObject, 'HH:mm');

      const status = isStoreAboutToClose(usersFormattedTime, storeClosingTime);
      setBannerVisible(status);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const userZonedTime = zonedTimeToUtc(new Date(), siteConfig.timeZone);
    const interval = setInterval(() => getBannerStatus(userZonedTime), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <BannerHeaderContainer>
        <BannerContainer>
          <Banner
            bannerText={`${t('restaurant.menu.currentMenuEnds')} ${formatDisplayTime(closingTimeToday)}.`}
            hasRoundedCorners={false}
            isVisible={bannerVisible}
          />
        </BannerContainer>
      </BannerHeaderContainer>
      <MenuHeaderContainer
        headerImageUrl={store.primary}
        alt="A view of the restaurant"
        data-testid="menu-header-container"
      >
        {store.primary === '' && (
          <RestaurantPlaceholder screenWidth={screenWidth}>
            <PlaceholderImage
              height="126"
              width="126"
              PlaceholderSVG={restaurantHeaderPlaceholderSVG}
            />
          </RestaurantPlaceholder>
        )}
        {!isOpen && (
          <StyledClosed>
            <StatusLabel labelText={t('restaurant.main.closed')} />
          </StyledClosed>
        )}

        {isOpen && !store.isMobileActive && (
          <StyledClosed>
            <StatusLabel
              labelText={`${t('restaurant.main.orderingUnavailableTitle')}`}
            />
          </StyledClosed>
        )}
        <Typography
          variant="h5"
          component="h2"
          color={theme.colors.base.white}
          data-testid="restaurant-name"
        >
          {store.name}
        </Typography>
        {store.description && (
          <RestaurantSubtitleContainer>
            <RestaurantSubtitle
              variant="body2"
              data-testid="restaurant-description"
            >
              {store.description}
            </RestaurantSubtitle>
          </RestaurantSubtitleContainer>
        )}
        <BottomLineStyled>
          <HeaderInfoContainer
            data-testid="restaurant-info-icon"
            onClick={handleClickOpen}
            onKeyDown={handleKeyOpen}
            tabIndex={0}
          >
            <InfoOutlinedIcon />
            <RestaurantInfoText>{t('restaurant.main.info')}</RestaurantInfoText>
          </HeaderInfoContainer>
          {store.hasAdvancedOrdering && (
            <Selection
              label=""
              placeholder={t('restaurant.main.selectPickupTime')}
              value={
                displayTimes?.length
                  ? pickupTimeDisplay
                  : t('restaurant.main.unavailable')
              }
              options={displayTimes ?? []}
              onChange={handleTimeSelection}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: theme.spacing(20),
                paddingBottom: theme.spacing(0),
                paddingTop: theme.spacing(0),
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
                margin: 0,
                display: 'inline',
                color: '#000000',
                '&:hover': {
                  backgroundColor: '#DDD'
                }
              }}
              disabled={!displayTimes?.length}
            />
          )}
        </BottomLineStyled>
        {store && (
          <StoreInfoDialog
            open={open}
            handleClose={handleClickClose}
            store={store}
          />
        )}
      </MenuHeaderContainer>
    </>
  );
};

export default MenuHeader;
